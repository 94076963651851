'use client';

import { GradientBackground } from '@ghosts-inc/design-system/components';
import Box from '@mui/material/Box';
import Image from 'next/image';
import ghostCoin from '@/assets/ghost-coin.webp';
import { UserMenu } from '@/components/UserMenu';
import { useGhostStore } from '@/legacy/store';

const CoinGradient = (): JSX.Element => {
  const currentUser = useGhostStore((state) => state.user);

  return (
    <GradientBackground height="100%" display="flex" alignItems="center" justifyContent="center">
      {Boolean(currentUser) && (
        <Box sx={{ position: 'absolute', top: 40, right: 80 }}>
          <UserMenu isDark />
        </Box>
      )}
      <Image src={ghostCoin} alt="Ghost Coin" unoptimized priority={true} />
    </GradientBackground>
  );
};

export default CoinGradient;
